@import "./styles/config.module";

html,
body {
  overflow-x: hidden;
  margin: unset;
  background-color: $background-color;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: unset;
}

li,
ul, h1, h2,h3,h4,h5,h6, span {
  color: $color-secondary;
}
