@import "../../../styles/config.module";

.header {
  margin: size(4) 0 size(5.5) 0;
  &__menuItem {
    cursor: pointer;
  }

  &__logo {
    height: size(4);
  }
}
