@import "../../../styles/config.module";

.footer {
  margin-top: size(4);
  &__text.MuiTypography-body1 {
    color: $color-grey;
    font-family: $app-fonts-regular;
    @media only screen and (max-width: $md-screen) {
      text-align: center;
    }
  }

  &__textContainer.MuiGrid-root {
    @media only screen and (max-width: $md-screen) {
      justify-content: center;
    }
  }
}
